// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import UIkit from "uikit";
import UIkitIcons from "uikit/dist/js/uikit-icons";
import Rails from "rails-ujs";
import SlimSelect from "slim-select";
import Turbolinks from "turbolinks";
// require("@rails/activestorage").start();
// require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const modalDeleteAttr = "data-delete";

const handleConfirm = function(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  }
};

const allowAction = element => {
  if (element.getAttribute(modalDeleteAttr) === null) {
    return true;
  }

  showConfirmationDialog(element);
  return false;
};

const showConfirmationDialog = element => {
  const text = element.getAttribute(modalDeleteAttr);

  UIkit.modal.confirm(text).then(
    function() {
      element.removeAttribute(modalDeleteAttr);
      element.click();
    },
    function() {}
  );
};

Rails.delegate(document, `a[${modalDeleteAttr}]`, "click", handleConfirm);

Rails.start();
Turbolinks.start();
Turbolinks.setProgressBarDelay(0);
UIkit.use(UIkitIcons);

document.addEventListener("turbolinks:load", () => {
  let slimSelects = [...document.getElementsByClassName("slim-select")];
  slimSelects.forEach(select => {
    new SlimSelect({
      select: `#${select.id}`
    });
  });
});
